export default {
  data: function() {
    return {
      pageNavs: [
        {
          id: '01',
          name: '店舗情報一覧',
          path: '/event/shop',
          routeName: 'EventShop',
          includes: false,
        },
        {
          id: '02',
          name: '店舗アカウント登録',
          path: '/event/shop/register',
          routeName: 'EventShopRegister',
          includes: false,
        },
        {
          id: '03',
          name: 'グループ一覧',
          path: '/event/shop/group',
          routeName: 'EventShopGroup',
          includes: false,
        },
      ],
    };
  },
};
